export const Games = ({
  height = '24',
  width = '24',
  color = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.937 7.53C19.227 4.119 15.803 2 12 2C6.486 2 2 6.486 2 12C2 17.514 6.486 22 12 22C15.803 22 19.227 19.881 20.937 16.47C21.0516 16.2415 21.0743 15.9777 21.0005 15.733C20.9266 15.4882 20.7619 15.281 20.54 15.154L15.017 12L20.539 8.847C21 8.583 21.175 8.005 20.937 7.53ZM12.504 11.132C12.3508 11.2193 12.2234 11.3456 12.1348 11.498C12.0462 11.6505 11.9995 11.8237 11.9995 12C11.9995 12.1763 12.0462 12.3495 12.1348 12.502C12.2234 12.6544 12.3508 12.7807 12.504 12.868L18.677 16.393C17.953 17.5032 16.9629 18.4148 15.7967 19.0448C14.6306 19.6747 13.3254 20.0031 12 20C7.589 20 4 16.411 4 12C4 7.589 7.589 4 12 4C13.3253 3.99688 14.6304 4.32513 15.7965 4.95491C16.9626 5.5847 17.9528 6.49602 18.677 7.606L12.504 11.132Z"
        fill={color}
      />
      <path
        d="M11.5 9C12.3284 9 13 8.32843 13 7.5C13 6.67157 12.3284 6 11.5 6C10.6716 6 10 6.67157 10 7.5C10 8.32843 10.6716 9 11.5 9Z"
        fill={color}
      />
    </svg>
  )
}

export default Games
