import { Flex, Text, Image, Link, Tooltip } from '@chakra-ui/react'
import { Notification } from '@olagg/db-types';
import { useTranslation } from 'react-i18next';
import { DEFAULT_IMAGE } from './config';
import { cdn } from '@/utils/cloudflareCdn';
import { IoMail, IoMailOpenOutline } from "react-icons/io5";
import { analytics } from "../../analytics"
import { useNavigate } from 'react-router-dom';

const NotificationCard = ({
  notification,
  markAsRead,
  markAsUnread,
}: {
  notification: Notification,
  markAsRead: (notification: Notification) => void,
  markAsUnread: (notification: Notification) => void,
}) => {
  const { t, i18n: { language: lang } } = useTranslation();
  const navigate = useNavigate();

  const triggerAnalytics = () => {
    analytics()?.track("Notification Opened", {
      notification_type: 'in_app',
      title: notification.title,
      body: notification.body, 
      link: notification.link
    })
  }

  const handleCtaClick = (notification: Notification) => {
    triggerAnalytics();
    markAsRead(notification);
    if (notification.link.startsWith('http')) {
      window.open(notification.link, "_blanck");
    } else {
      navigate(notification.link);
    }
  }

  return (
    <Flex _last={{ borderBottom: 'none' }} justifyContent='start' alignItems='center' borderBottom='1px solid #777' p='6' gap='5'>
      <Flex justifyContent='center' alignItems='center' minW='50' h='50px'>
        <Image w='50px' h='50px' src={cdn(notification.image ?? DEFAULT_IMAGE, { w: '50px', h: '50px' })} objectFit='contain' alt='Notification image' />
      </Flex>
      <Flex direction='column' w='full' position='relative'>
        <Flex py='2' pr='1' justifyContent='space-between' alignItems='center'>
          <Text mr='2' fontWeight='semibold' fontSize='0.9rem' noOfLines={1}>
            {lang === 'es' ? notification.title : (notification.extraData?.[lang]?.title || notification.title)}
          </Text>
          {notification.readAt
            ? <Tooltip label={t('Notifications.tooltips.markAsUnread')}>
                <span><IoMailOpenOutline cursor='pointer' onClick={() => markAsUnread(notification)} /></span>
              </Tooltip>
            : <Tooltip label={t('Notifications.tooltips.markAsRead')}>
                <span><IoMail cursor='pointer' onClick={() => markAsRead(notification)} /></span>
              </Tooltip>}
        </Flex>
        <Text textColor='gray.300' fontSize='0.9rem' noOfLines={3}>
          {lang === 'es' ? notification.body : (notification.extraData?.[lang]?.body || notification.body)}
        </Text>
        {notification.link && (
          <Link fontSize='0.9rem' pt='3' onClick={() => handleCtaClick(notification)}>
            {lang === 'es' ? notification.linkLabel : (notification.extraData?.[lang]?.linkLabel || notification.linkLabel || t('Notifications.defaultLinkLabel')) }
          </Link>
        )}
      </Flex>     
    </Flex>
  )
}

export default NotificationCard;
