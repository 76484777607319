
import { useEffect, useState } from "react";
import ChooseMethod from "./components/ChooseMethod";
import UpdateProfile from "./components/UpdateProfile";
import VerificationCode from "./components/VerificationCode";
import SetPasskey from "./components/SetPasskey";
import { useOnboardingStore } from "@olagg/store";
import { STEPS } from "./constants";
import LinkDiscord from "./components/LinkDiscord";
import { useSearchParams } from "react-router-dom";

import WelcomeProfileEdit from "./components/WelcomeProfileEdit";
import PlatformsPreferences from "./components/PlatformsPreferences";
import OpportunitiesPreferences from "./components/OpportunitiesPreferences";
import GameCategoriesPreferences from "./components/GameCategoriesPreferences";
import OBCompletedModal from "./components/OBCompletedModal";

const Authenticate = () => {
  const [email, setEmail] = useState('' as string)
  const [code, setCode] = useState<string>()
  const { step, setStep } = useOnboardingStore()
  const [isInvalid, setIsInvalid] = useState(false)

  const [searchParams] = useSearchParams()
  const verificationCode = searchParams.get('code')
  const verificationEmail = searchParams.get('verificationEmail')

  const handleInvalid = (value: boolean) => {
    setIsInvalid(value)
    setStep(STEPS.chooseMethod)
  }

  useEffect(() => {
    if (verificationCode && verificationEmail) {
      setEmail(verificationEmail)
      setCode(verificationCode)
      setStep(STEPS.verificationCode)
    }
  }, [])

  return (
    <>
      {step === STEPS.chooseMethod && <ChooseMethod email={email} changeEmail={setEmail} isInvalid={isInvalid} />}
      {step === STEPS.welcomeProfileEdit && <WelcomeProfileEdit />}
      {step === STEPS.platformsPreferences && <PlatformsPreferences />}
      {step === STEPS.opportunitiesPreferences && <OpportunitiesPreferences />}
      {step === STEPS.gameCategoriesPreferences && <GameCategoriesPreferences />}
      {step === STEPS.completedModal && <OBCompletedModal />}
      {step === STEPS.verificationCode && <VerificationCode email={email} verificationCode={code} handleInvalid={handleInvalid} />}
      {step === STEPS.setPasskey && <SetPasskey email={email} />}
      {step === STEPS.completeData && <UpdateProfile />}
      {step === STEPS.linkDiscord && <LinkDiscord />}
    </>
  )
}

export default Authenticate;
