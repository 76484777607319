export const Quests = ({
  height = '24',
  width = '24',
  color = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17.8127 3.838C17.6278 3.57906 17.3839 3.36796 17.1011 3.22221C16.8183 3.07647 16.5048 3.00029 16.1867 3H7.81267C7.16867 3 6.56067 3.313 6.14567 3.899L2.14567 10.48C2.0339 10.6636 1.98434 10.8784 2.00433 11.0924C2.02433 11.3064 2.11282 11.5083 2.25667 11.668L11.2567 21.668C11.3499 21.7729 11.4643 21.8568 11.5924 21.9143C11.7204 21.9718 11.8591 22.0016 11.9994 22.0017C12.1398 22.0018 12.2786 21.9722 12.4067 21.9149C12.5347 21.8575 12.6493 21.7737 12.7427 21.669L21.7427 11.669C21.8868 11.5094 21.9755 11.3075 21.9955 11.0934C22.0155 10.8793 21.9658 10.6645 21.8537 10.481L17.8127 3.838ZM11.9997 19.505L5.24467 12H18.7537L11.9997 19.505ZM4.77667 10L7.81267 5L16.1447 4.938L19.2217 10H4.77667Z"
        fill={color}
      />
    </svg>
  )
}

export default Quests
