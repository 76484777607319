import { ROUTES } from "@/routes"
import { Flex, Button, Image } from "@chakra-ui/react"
import { useNavigate } from "react-router-dom"
import { useTranslation } from "react-i18next"
import GreenRuby from './assets/Ruby.gif'
import Chest from './assets/chest.png'

const CtaButtons = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()

  return (
    <>
      <Button
        py='23px'
        pl='2'
        pr='5'
        fontSize='14px'
        fontWeight='400'
        color='white'
        background='#370A70'
        borderRadius='10px'
        _hover={{ bg: 'whiteAlpha.500' }}
        onClick={() => navigate(ROUTES.QUESTS)}
      >
        <Flex alignItems='center'>
          <Image src={GreenRuby} w='35px' alt='green ruby' />
          {t('navbar.gamesMenu.quests')}
        </Flex>
      </Button>

      <Button
        py='6'
        pl='2'
        pr='5'
        fontSize='14px'
        fontWeight='400'
        color='white'
        background='#370A70'
        borderRadius='10px'
        _hover={{ bg: 'whiteAlpha.500' }}
        onClick={() => navigate(ROUTES.TREASURES_GAME)}
      >
        <Flex alignItems='center' gap='2' ml='2'>
          <Image src={Chest} w='25px' alt='green ruby' />
          {t('navbar.gamesMenu.miniGames')}
        </Flex>
      </Button>
    </>
  )
}

export default CtaButtons
