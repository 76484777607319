export const CalendarDayCircle = ({
  height = '21px',
  width = '18px',
  color = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path d="M11.5 16.0438C10.8 16.0438 10.2083 15.8022 9.725 15.3188C9.24167 14.8355 9 14.2438 9 13.5438C9 12.8438 9.24167 12.2522 9.725 11.7688C10.2083 11.2855 10.8 11.0438 11.5 11.0438C12.2 11.0438 12.7917 11.2855 13.275 11.7688C13.7583 12.2522 14 12.8438 14 13.5438C14 14.2438 13.7583 14.8355 13.275 15.3188C12.7917 15.8022 12.2 16.0438 11.5 16.0438ZM2 20.0438C1.45 20.0438 0.979167 19.848 0.5875 19.4563C0.195833 19.0647 0 18.5938 0 18.0438V4.04382C0 3.49382 0.195833 3.02299 0.5875 2.63132C0.979167 2.23966 1.45 2.04382 2 2.04382H3V0.0438232H5V2.04382H13V0.0438232H15V2.04382H16C16.55 2.04382 17.0208 2.23966 17.4125 2.63132C17.8042 3.02299 18 3.49382 18 4.04382V18.0438C18 18.5938 17.8042 19.0647 17.4125 19.4563C17.0208 19.848 16.55 20.0438 16 20.0438H2ZM2 18.0438H16V8.04382H2V18.0438ZM2 6.04382H16V4.04382H2V6.04382Z" fill={color}/>
    </svg>
  )
}

export default CalendarDayCircle
