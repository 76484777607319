export const Xp = ({
  color,
  transparentBackground = false,
  ...props
}: React.SVGProps<SVGSVGElement> & { transparentBackground?: boolean }) => {
  const fill = color == 'olaGradient' ? 'url(#fillGradient)' : color != 'olaGradient' && color != '' ? color : 'white';
  const bgFill = transparentBackground ? 'none' : (color ?? 'url(#fillGradient)');

  return (
    <svg viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect width="18.5" height="18.5" rx="9.25" fill={bgFill}/>
      <path d="M15.8315 6.44023C15.3372 6.04482 14.6337 5.84676 13.7224 5.84676H13.4615V5.84399H8.88375C8.67802 5.84399 8.48127 5.93194 8.34389 6.08567L7.00875 7.57522H10.3625L9.37735 12.5154L7.63007 9.26905L6.68566 7.54613L5.85448 5.84676H3.41063L5.12478 9.13955L1.92706 12.656H4.66155L6.13269 10.904L6.97906 12.656H11.6369L11.9703 10.983H13.1398C13.8377 10.983 14.4438 10.8632 14.9582 10.6229C15.4725 10.3833 15.8701 10.046 16.1511 9.61113C16.4321 9.17694 16.5729 8.6742 16.5729 8.10359C16.5729 7.39032 16.3258 6.83564 15.8308 6.44023H15.8315ZM13.9937 8.97404C13.8191 9.13332 13.5733 9.21226 13.2564 9.21226H12.3189L12.6365 7.61677H13.4021C13.6672 7.61677 13.8757 7.67009 14.0276 7.77742C14.1794 7.88476 14.2554 8.06134 14.2554 8.30787C14.2554 8.59318 14.1684 8.81546 13.9937 8.97404Z" fill={fill}/>
      <defs>
        <linearGradient id="fillGradient" x1="0" y1="18.5" x2="18.5" y2="18.5" gradientUnits="userSpaceOnUse">
          <stop stopColor="#4E10FF"/>
          <stop offset="1" stopColor="#AB17DF"/>
        </linearGradient>
      </defs>
    </svg>
  )
}

export default Xp