import { ConsentManager } from '@segment/consent-manager';
import inEU from '@segment/in-eu';
import { Box } from '@chakra-ui/react';

const CookiesConsent = () => {
  const bannerContent = (
    <span>
      We use cookies (and other similar technologies) to collect data to improve your experience on
      our site. By using our website, you’re agreeing to the collection of data as described in our{' '}
      <a href="/posts/cookies" target="_blank">Website Data Collection Policy</a>.
    </span>
  )
  const bannerSubContent = 'You can change your preferences at any time.'
  const preferencesDialogTitle = 'Website Data Collection Preferences'
  const preferencesDialogContent = 'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.'
  const cancelDialogTitle = 'Are you sure you want to cancel?'
  const cancelDialogContent = 'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.'

  return (
    <Box zIndex={1000} position='fixed' bottom='0' left='0' right='0' id="consentManager">
      <ConsentManager
        writeKey={import.meta.env.VITE_SEGMENT_KEY || '2bVFMstw3pn6OQgzDOndHgqzHd20eRIq'}
        cdnHost='analytics-cdn.olagg.io'
        shouldRequireConsent={import.meta.env.VITE_TEST_COOKIE_CONSENT === 'true' ? () => true : inEU}
        bannerContent={bannerContent}
        bannerSubContent={bannerSubContent}
        preferencesDialogTitle={preferencesDialogTitle}
        preferencesDialogContent={preferencesDialogContent}
        cancelDialogTitle={cancelDialogTitle}
        cancelDialogContent={cancelDialogContent}
      />
    </Box>
  )
}

export default CookiesConsent;
