import { Flex, Spinner, Text, Image } from '@chakra-ui/react'
import { FaCheck } from 'react-icons/fa'
import { useTranslation } from 'react-i18next'
import Xp from '@olagg/ui-kit/svg/Xp';
import { Link } from 'react-router-dom';

const XpPill = ({ active = false, score = 0, isCompleted = false, isLoading = false, showCheck = false, v1 = false, v2 = false }) => {

    const { t, i18n } = useTranslation();

    const RenderV1 = () => {
        return (
            <Flex
                border={active ? 'none' : isCompleted ? '1px solid #87FFDB' : '1px solid rgba(236, 237, 239, 0.60)'}
                borderRadius={'100px'}
                px='8px'
                height={'24px'}
                alignItems='center'
                background={active ? 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)' : 'transparent'}
            >
                {showCheck && <Flex mr='3px'><FaCheck color='white' size='10px' /></Flex>}
                {isCompleted ? <Text fontSize='12px' color={'#87FFDB'}>
                    {isLoading ? <Flex><Spinner size='xs' color='#87FFDB' /></Flex> : <>{t('OnBoarding.claim')}</>}
                </Text> : <Text fontSize='12px' color={'white'}>{score}</Text>}
                <Link to={`/${i18n.language}/mi-perfil`}>
                    <Text w='25px' cursor={'pointer'}>
                        <Xp color={isCompleted ? '#87FFDB' : ''} transparentBackground />
                    </Text>
                </Link>
            </Flex>
        )
    }

    const RenderV2 = () => {
        return (
            <Flex height={'25px'} alignItems={'center'} p={'2px 10px'} background={active ? 'linear-gradient(90deg, rgba(78, 16, 255, 1) 0%, rgba(171, 23, 223, 1) 100%)' : 'linear-gradient(90deg, rgba(78, 16, 255, 0.10) 0%, rgba(171, 23, 223, 0.10) 100%)'} borderRadius={'2px'}>
                {showCheck && <Image src={'/images/badge_check.png'} w='16px' h='16px' mr='2px' />}
                <Text variant={active ? 'white' : 'filledGradient'} fontSize={'14px'} fontWeight='700'>{score}</Text>
                <Link to={`/${i18n.language}/mi-perfil`}>
                    <Text w='28px' cursor={'pointer'}>
                        <Xp color={active ? 'white' : 'olaGradient'} transparentBackground />
                    </Text>
                </Link>
            </Flex>
        )
    }

    return (
        <>
            {v1 && <RenderV1 />}
            {v2 && <RenderV2 />}
        </>
    )

}

export default XpPill
