export const NavbarLogoOla = ({
  width = '141px',
  height = '48px',
  text = true,
  ...props
} : React.SVGProps<SVGSVGElement> & { text?: boolean }) => (
  <svg
    width={width}
    height={height}
    viewBox={`0 0 ${text ? '141' : '48'} 48`}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    {text && (
      <>
        <path d="M84.0976 17.253C83.9971 16.1903 84.97 15.2438 86.0328 15.3548C89.1102 15.3601 92.1875 15.3231 95.2648 15.376C96.4704 15.3337 97.0467 16.5604 97.0256 17.602C97.0784 22.6252 97.0308 27.6483 97.0467 32.6714C95.5028 32.6714 93.9641 32.6714 92.4201 32.6714C92.4201 31.2597 92.4201 29.8532 92.4201 28.4414C91.1406 28.4414 89.8663 28.4414 88.5867 28.4414C88.5867 29.8532 88.5867 31.2597 88.5867 32.6714C87.0903 32.6714 85.5887 32.6714 84.0923 32.6714C84.0923 27.532 84.0817 22.3925 84.0976 17.253ZM88.5603 19.2359C88.6026 21.023 88.5867 22.8155 88.5867 24.608C89.861 24.608 91.1406 24.608 92.4201 24.608C92.2351 22.7732 92.8167 20.7111 92.124 19.0138C90.9396 18.8552 89.6918 18.7759 88.5603 19.2359Z" fill="white"/>      <path d="M108.182 17.1263C108.193 16.0318 109.303 15.2334 110.35 15.355C113.887 15.3444 117.43 15.355 120.973 15.355C120.973 16.6346 120.973 17.9089 120.973 19.1885C118.239 19.1885 115.511 19.1885 112.777 19.1885C112.788 22.4033 112.745 25.6234 112.803 28.8382C114.104 28.8382 115.41 28.8382 116.716 28.8382C116.737 27.823 116.743 26.813 116.753 25.8031C115.949 25.7978 115.156 25.7978 114.363 25.7978C114.363 24.5183 114.363 23.244 114.363 21.9644C117.007 21.9644 119.651 21.9644 122.294 21.9644C122.294 23.244 122.295 24.5236 122.284 25.8084C121.924 25.8031 121.205 25.7978 120.846 25.7926C120.84 27.7013 120.825 29.6048 120.862 31.5136C119.936 33.0259 118.107 32.6557 116.6 32.6769C114.31 32.6293 112.021 32.7509 109.742 32.6293C108.695 32.5553 108.055 31.4396 108.156 30.472C108.161 26.0199 108.113 21.5731 108.182 17.1263Z" fill="white"/>
        <path d="M53.2983 15.6192C54.0491 15.1486 54.9744 15.4183 55.8099 15.3443C58.443 15.3971 61.0815 15.2861 63.7147 15.3866C64.677 15.3178 65.375 16.2431 65.3115 17.142C65.3274 21.7474 65.3274 26.3581 65.3168 30.9688C65.3803 31.8201 64.5818 32.6344 63.7411 32.6344C60.5528 32.719 57.3644 32.6608 54.1813 32.6661C53.2666 32.6873 52.3465 31.8307 52.3836 30.9054C52.3307 26.5168 52.3888 22.1228 52.3571 17.7342C52.2725 16.9305 52.5316 16.0211 53.2983 15.6192ZM56.9943 19.1883C57.0048 22.3978 56.9784 25.6126 57.0101 28.8221C58.2738 28.838 59.5376 28.838 60.8013 28.838C60.8489 25.6285 60.8171 22.4189 60.8224 19.2094C59.5481 19.183 58.2686 19.1883 56.9943 19.1883Z" fill="white"/>
        <path d="M70.2124 32.6715C70.2177 26.8975 70.2071 21.1236 70.2177 15.3496C71.7564 15.3549 73.3003 15.3549 74.839 15.3549C74.839 19.8493 74.839 24.3437 74.839 28.838C76.6473 28.838 78.4503 28.838 80.2587 28.838C80.2587 30.1176 80.2587 31.3919 80.2587 32.6715C76.9117 32.6715 73.5594 32.6715 70.2124 32.6715Z" fill="white"/>
        <path d="M126.413 17.2162C126.371 16.2327 127.291 15.2862 128.285 15.3603C131.923 15.3285 135.561 15.3814 139.193 15.3391C139.214 16.624 139.214 17.9036 139.214 19.1884C136.481 19.1884 133.752 19.1884 131.019 19.1884C131.019 22.4032 131.019 25.6233 131.019 28.8381C132.33 28.8381 133.647 28.8487 134.963 28.7905C134.979 27.7965 134.984 26.7971 134.99 25.7978C134.191 25.7978 133.398 25.7978 132.605 25.7978C132.605 24.5182 132.605 23.2439 132.605 21.9644C135.249 21.9644 137.893 21.9644 140.536 21.9644C140.536 23.2439 140.536 24.5182 140.536 25.7925C140.171 25.7978 139.442 25.7978 139.077 25.8031C139.05 27.696 139.172 29.5995 139.013 31.4924C138.643 32.1217 138.051 32.7509 137.253 32.6663C134.255 32.6768 131.251 32.698 128.248 32.6504C127.053 32.7244 126.228 31.5136 126.397 30.3979C126.397 26.004 126.36 21.6101 126.413 17.2162Z" fill="white"/>
      </>
    )}

    <mask id="mask0_5428_8945"  maskUnits="userSpaceOnUse" x="0" y="1" width="45" height="46">
      <path d="M38.8036 8.81079H6.81546H0.711426L3.76719 14.0554L19.7575 41.5262L22.8133 46.7708L25.869 41.5262L41.8594 14.0554L44.9151 8.81079H38.8036ZM22.8133 39.7805L6.81546 12.3096H38.8036L22.8133 39.7805Z" fill="white"/>
      <path d="M22.8135 1.12964L5.05566 11.2994V31.6314L22.8135 41.8011L40.5714 31.6314V11.2994L22.8135 1.12964ZM37.0438 29.6182L24.5736 36.7571L22.8135 39.7879L21.0535 36.7571L8.58327 29.6182V15.3331L6.82321 12.3022H10.3508L22.821 5.16335L35.2912 12.3022H38.8188L37.0588 15.3331V29.6182H37.0438Z" fill="white"/>
      <path d="M22.8145 29.8263L19.5864 24.292H15.4072L22.8145 37.0171L28.9035 26.5428H24.7243L22.8145 29.8263Z" fill="white"/>
      <path d="M26.6188 13.6096L20.7319 23.7199L22.814 27.3005L25.9896 21.8405H27.4576L25.383 25.4136H29.5622L36.4377 13.6096H26.6188ZM27.6074 19.0696L28.7758 17.0565H30.2437L29.0754 19.0696H27.6074Z" fill="white"/>
      <path d="M25.1433 13.6096H9.19043L14.7552 23.1628H19.5935L25.1433 13.6096ZM17.429 19.6862H16.9047L15.3769 17.0565H18.9569L17.429 19.6862Z" fill="white"/>
    </mask>

    <g mask="url(#mask0_5428_8945)">
      <path d="M47.2367 -0.891113H-1.58057V47.5283H47.2367V-0.891113Z" fill="url(#paint0_linear_5428_8945)"/>
    </g>

    <defs>
      <linearGradient id="paint0_linear_5428_8945" x1="41.5836" y1="3.15584" x2="7.91662" y2="39.9479" gradientUnits="userSpaceOnUse">
        <stop offset="0.0982117" stopColor="#FFAD4B"/>
        <stop offset="0.1522" stopColor="#FFA355"/>
        <stop offset="0.2455" stopColor="#FF8771"/>
        <stop offset="0.3668" stopColor="#FF599E"/>
        <stop offset="0.51" stopColor="#FF1BDC"/>
        <stop offset="0.5668" stopColor="#FF00F6"/>
        <stop offset="0.6236" stopColor="#FB00F6"/>
        <stop offset="0.6817" stopColor="#EF00F6"/>
        <stop offset="0.7405" stopColor="#DA00F6"/>
        <stop offset="0.7998" stopColor="#BD00F7"/>
        <stop offset="0.8595" stopColor="#9800F7"/>
        <stop offset="0.9195" stopColor="#6B01F8"/>
        <stop offset="0.9786" stopColor="#3601F9"/>
        <stop offset="1" stopColor="#2101F9"/>
      </linearGradient>
    </defs>
  </svg>
)

export default NavbarLogoOla