import { Button } from "@chakra-ui/react"
import { useTranslation } from "react-i18next"
import { useOnboardingStore } from "@olagg/store"

const LoginButton = () => {
  const { t } = useTranslation()
  const { openOnboarding } = useOnboardingStore()

  return (
    <Button
      variant="filledGradient"
      color="white"
      w="112px"
      fontSize={'18px'}
      type="button"
      _hover={{ transform: 'scale(0.98)' }}
      transition='all 0.2s ease-out'
      onClick={() => openOnboarding()}
    >
      {t('navbar.access')}
    </Button>
  )
}

export default LoginButton