import { Badge, Flex, Image, Text, useMediaQuery } from '@chakra-ui/react'
import { useEffect, useState } from 'react'

import { Spinner } from '@chakra-ui/react'
import { Xp } from '@olagg/ui-kit/svg/Xp'
import avatar from '@olagg/ui-kit/svg/avatar_circle.svg'
import { useAuthStore } from '@olagg/store'
import { useTranslation } from 'react-i18next'

const UserNav = () => {
  const { me } = useAuthStore()
  const [imgAvatar, setImgAvatar] = useState(avatar)
  const [isLoading, setIsLoading] = useState(true)
  const { t } = useTranslation()
  const [isMobile] = useMediaQuery('(max-width: 480px)')

  useEffect(() => {
    setImgAvatar(me?.avatar || avatar)
    setIsLoading(false)
  }, [me])

  return (
    <Flex>
      {isLoading ? (
        <Flex height={'48px'} width={'48px'} alignItems={'center'} justifyContent='center'>
          <Spinner color='white' size={'sm'}/>
        </Flex>
      ) : (
        <>
          <Flex direction={'row'}
            gap={'8px'}
            alignItems='center'
            justifyContent={'center'}
            marginLeft='8px'
          >
            <Flex alignItems='center' justifyContent='center' flexWrap='wrap'>
              <Badge
                px='3'
                py='1'
                mr='2'
                bgColor='#D59A27'
                borderRadius='50px'
                background='linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)'
                color='white'
                fontSize='12px'
                fontWeight='500'
                textTransform='inherit'
              >
                {t('navbar.myAccountNav.level')} {me?.level.value}
              </Badge>
              <Flex alignItems={'center'}>
                <Text color={'white'} fontSize='14px' fontWeight='600'>
                  {me?.level.score || 0}
                </Text>
                <Text w='25px' mt='2px'>
                  <Xp color='white' transparentBackground />
                </Text>
              </Flex>
            </Flex>
            <Flex
              width='32px'
              minWidth='32px'
              height='32px'
              minHeight='32px'
              position='relative'
            >
              <Image
                loading='lazy'
                fallbackSrc={avatar}
                width='100%'
                height='100%'
                src={imgAvatar}
                objectFit={'cover'}
                borderRadius='50%'
              />
              {isMobile && me?.unreadNotifications > 0 && (
                <Flex
                  position='absolute'
                  top='-5px'
                  right='-7px'
                  bg='#AB17DF'
                  borderRadius='100%'
                  px='1'
                  fontSize='10px'
                  fontWeight='600'
                  color='white'
                >
                  {me?.unreadNotifications}
                </Flex>
              )}
            </Flex>
          </Flex>
        </>
      )}
    </Flex>
  )
}

export default UserNav
