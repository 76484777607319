import { Box, Flex, Menu, MenuButton, MenuItem, MenuList, Image, Text } from "@chakra-ui/react"
import { DownArrow } from "@olagg/ui-kit"
import { useNavigate } from "react-router-dom"
import FlagSp from '@olagg/ui-kit/svg/FlagSp.svg'
import FlagEn from '@olagg/ui-kit/svg/FlagEn.svg'
import FlagPt from '@olagg/ui-kit/svg/FlagPt.svg'
import { useTranslation } from 'react-i18next'

const LanguageMenu = ({ closeMenu }: { closeMenu?: () => void }) => {
  const languages = [
    { name: 'Español', value: 'es' },
    { name: 'English', value: 'en' },
    { name: 'Portugués', value: 'pt' }
  ]

  const { i18n } = useTranslation()
  const navigate = useNavigate()
  const activeLanguage = languages.find(lang => lang.value === i18n.language)

  const changeLang = (newLang: string) => {
    const currentPath = window.location.pathname.split('/').slice(2).join('/');
    const searchParams = location.search;
    i18n.changeLanguage(newLang);
    localStorage.setItem('i18n_lang', newLang);
    navigate(`/${newLang}/${currentPath}${searchParams}`, { replace: true });
  }

  const renderFlag = (lang: typeof languages[number]['value']) => {
    switch (lang) {
      case 'es':
        return <Image src={FlagSp} alt='Spanish flag' boxSize='20px' />
      case 'en':
        return <Image src={FlagEn} alt='English flag' boxSize='20px' />
      case 'pt':
        return <Image src={FlagPt} alt='Portuguese flag' boxSize='20px' />
      default:
        return null
    }
  }

  return (
    <Menu>
      {({ isOpen }) => (
        <>
          <MenuButton
            borderRadius='10px'
            color='#fff'
            fontSize='16px'
            fontWeight={isOpen ? "bold" : "400"}
            position={'relative'}
            bg='#370A70'
            _hover={{ bg: 'whiteAlpha.500' }}
            p='11px'
          >
            <Flex alignItems="center">
              {renderFlag(activeLanguage?.value)}
              <Text ml='2' mr='3'>{activeLanguage?.value.toUpperCase()}</Text>
              <Box
                transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
                transition='transform 0.2s ease-out'
              >
                <DownArrow />
              </Box>
            </Flex>
          </MenuButton>

          <MenuList
            p='2'
            bg='#0C0324'
            borderWidth='0px'
            flexDirection='column'
            justifyContent='space-evenly'
            borderRadius='5'
            minW='0'
          >
            {languages.map((item, index) => (
              <MenuItem
                bg='#0C0324'
                color={'#fff'}
                width={'auto'}
                fontWeight={i18n.language == item.value ? 'bold' : '300'}
                _hover={{ fontWeight: 'bold' }}
                key={index}
                onClick={() => {
                  changeLang(item.value)
                  closeMenu?.()
                }}
              >
                {item.name}
              </MenuItem>
            ))}
          </MenuList>
        </>
      )}
    </Menu>
  )
}

export default LanguageMenu
