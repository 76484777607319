export const Case = ({
  height = '16',
  width = '16',
  color = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 16 16"
    fill="none"
    {...props}
    >
      <path
      d="M1.6 15.5999C1.16 15.5999 0.783467 15.4434 0.4704 15.1303C0.157333 14.8172 0.000533333 14.4404 0 13.9999V5.1999C0 4.7599 0.1568 4.38337 0.4704 4.0703C0.784 3.75724 1.16053 3.60044 1.6 3.5999H4.8V1.9999C4.8 1.5599 4.9568 1.18337 5.2704 0.870302C5.584 0.557236 5.96053 0.400436 6.4 0.399902H9.6C10.04 0.399902 10.4168 0.556702 10.7304 0.870302C11.044 1.1839 11.2005 1.56044 11.2 1.9999V3.5999H14.4C14.84 3.5999 15.2168 3.7567 15.5304 4.0703C15.844 4.3839 16.0005 4.76044 16 5.1999V13.9999C16 14.4399 15.8435 14.8167 15.5304 15.1303C15.2173 15.4439 14.8405 15.6004 14.4 15.5999H1.6ZM1.6 13.9999H14.4V5.1999H1.6V13.9999ZM6.4 3.5999H9.6V1.9999H6.4V3.5999Z"
      fill={color}/>
    </svg>
  )
}

export default Case