export const FrontHand = ({
  height = '21px',
  width = '17px',
  color = 'currentColor',
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 17 21" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.14773 10.522V1.43106C8.14773 1.17349 8.23485 0.957579 8.40909 0.783336C8.58333 0.609094 8.79924 0.521973 9.05682 0.521973C9.31439 0.521973 9.5303 0.609094 9.70455 0.783336C9.87879 0.957579 9.96591 1.17349 9.96591 1.43106V10.522H8.14773ZM4.51136 10.522V2.34015C4.51136 2.08258 4.59848 1.86667 4.77273 1.69243C4.94697 1.51818 5.16288 1.43106 5.42045 1.43106C5.67803 1.43106 5.89394 1.51818 6.06818 1.69243C6.24242 1.86667 6.32955 2.08258 6.32955 2.34015V10.522H4.51136ZM8.60227 20.522C6.45076 20.522 4.625 19.772 3.125 18.272C1.625 16.772 0.875 14.9462 0.875 12.7947V4.15834C0.875 3.90076 0.962121 3.68485 1.13636 3.51061C1.31061 3.33637 1.52652 3.24925 1.78409 3.24925C2.04167 3.24925 2.25758 3.33637 2.43182 3.51061C2.60606 3.68485 2.69318 3.90076 2.69318 4.15834V12.7947C2.69318 14.4462 3.26515 15.8439 4.40909 16.9879C5.55303 18.1318 6.95076 18.7038 8.60227 18.7038C10.2538 18.7038 11.6515 18.1318 12.7955 16.9879C13.9394 15.8439 14.5114 14.4462 14.5114 12.7947V9.61288C14.2538 9.61288 14.0379 9.7 13.8636 9.87425C13.6894 10.0485 13.6023 10.2644 13.6023 10.522V14.1583H10.875C10.375 14.1583 9.94697 14.3364 9.59091 14.6924C9.23485 15.0485 9.05682 15.4765 9.05682 15.9765V16.8856H7.23864V15.9765C7.23864 14.9765 7.5947 14.1205 8.30682 13.4083C9.01894 12.6962 9.875 12.3402 10.875 12.3402H11.7841V3.24925C11.7841 2.99167 11.8712 2.77576 12.0455 2.60152C12.2197 2.42728 12.4356 2.34015 12.6932 2.34015C12.9508 2.34015 13.1667 2.42728 13.3409 2.60152C13.5152 2.77576 13.6023 2.99167 13.6023 3.24925V7.95379C13.7538 7.90834 13.9015 7.87046 14.0455 7.84015C14.1894 7.80985 14.3447 7.7947 14.5114 7.7947H16.3295V12.7947C16.3295 14.9462 15.5795 16.772 14.0795 18.272C12.5795 19.772 10.7538 20.522 8.60227 20.522Z" fill={color}/>
    </svg>
  )
}

export default FrontHand
