export const FilledBook = ({
  height = '24px',
  width = '24px',
  color = 'currentColor',
  ...props
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.3926 0.51001H5.39258C4.18658 0.51001 2.39258 1.30901 2.39258 3.51001V17.51C2.39258 19.711 4.18658 20.51 5.39258 20.51H20.3926V18.51H5.40458C4.94258 18.498 4.39258 18.315 4.39258 17.51C4.39258 17.409 4.40158 17.319 4.41658 17.237C4.52858 16.662 4.99958 16.52 5.40358 16.51H19.3926C19.4106 16.51 19.4236 16.501 19.4416 16.5H20.3926V2.51001C20.3926 1.40701 19.4956 0.51001 18.3926 0.51001ZM18.3926 14.51H4.39258V3.51001C4.39258 2.70401 4.94258 2.52201 5.39258 2.51001H12.3926V9.51001L14.3926 8.51001L16.3926 9.51001V2.51001H18.3926V14.51Z"
        fill={color}
      />
    </svg>
  )
}

export default FilledBook
