export const Medal = ({
  height = '19px',
  width = '20px',
  color = 'currentColor'
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 17 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.44 17.501L8.5 15.771L5.56 17.501L6.34 14.161L3.75 11.921L7.16 11.631L8.5 8.50099L9.84 11.631L13.25 11.921L10.66 14.161M16.5 0.000991821H0.499997V2.00099L5.36 5.64099C3.66441 6.36458 2.27075 7.65157 1.41473 9.2843C0.558711 10.917 0.292858 12.7953 0.662136 14.6015C1.03141 16.4077 2.01316 18.0309 3.44133 19.1966C4.86951 20.3623 6.65647 20.999 8.5 20.999C10.3435 20.999 12.1305 20.3623 13.5587 19.1966C14.9868 18.0309 15.9686 16.4077 16.3379 14.6015C16.7071 12.7953 16.4413 10.917 15.5853 9.2843C14.7292 7.65157 13.3356 6.36458 11.64 5.64099L16.5 2.00099M14.5 13.001C14.5005 14.1373 14.1784 15.2503 13.5711 16.2107C12.9638 17.1711 12.0962 17.9392 11.0694 18.4258C10.0426 18.9124 8.89873 19.0974 7.77087 18.9593C6.64302 18.8213 5.57756 18.3658 4.69846 17.6458C3.81936 16.9259 3.16276 15.9711 2.80506 14.8926C2.44735 13.8141 2.40325 12.6562 2.67789 11.5536C2.95252 10.451 3.5346 9.44911 4.35639 8.6644C5.17819 7.87969 6.20591 7.34446 7.32 7.12099C8.09852 6.96095 8.90147 6.96095 9.68 7.12099C11.0389 7.39357 12.2616 8.12821 13.1402 9.20009C14.0189 10.272 14.4993 11.615 14.5 13.001ZM9.13 5.00099H7.87L3.87 2.00099H13.21L9.13 5.00099Z" fill={color}
      />
    </svg>
  )
}

export default Medal
