import { Flex, Image, Text } from "@chakra-ui/react"
import JoystickClosed from './assets/joystick.png'
import JoystickOpened from './assets/joystick-hover.png'
import { useState } from "react"
import { ROUTES } from "@/routes"
import { useNavigate } from "react-router-dom"
import Quests from './assets/quests-circle.png'
import Polls from './assets/polls-circle.png'
import Minigames from './assets/chest-circle.png'
import { useTranslation } from "react-i18next"
import { analytics } from '@/analytics'

const buttonBottom = 80
const buttonRight = 32
const buttonsSeparation = 70
const zIndex = 998

const FlyingButtons = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const [isOpen, setIsOpen] = useState(false)

  const handleClose = () => {
    setIsOpen(false);
    analytics()?.track('FAB dismissed');
  }

  const handleToggle = () => {
    if (isOpen) {
      handleClose();
    } else {
      setIsOpen(true);
      analytics()?.track('FAB opened');
    }
  }

  const handleClick = (route: string) => {
    setIsOpen(false);
    analytics()?.track('FAB option selected', {option: route});
    navigate(route);
  }

  return (
    <>
      {isOpen && (
        <Flex
          position='fixed'
          zIndex={zIndex}
          top='0'
          left='0'
          w='full'
          h='full'
          bg='blackAlpha.700'
          onClick={handleClose}
        ></Flex>
      )}

      <Image
        position='fixed'
        bottom={buttonBottom + 'px'}
        right={buttonRight + 'px'}
        src={isOpen ? JoystickOpened : JoystickClosed}
        w='72px'
        alt='Joystick'
        onClick={handleToggle}
        cursor='pointer'
        zIndex={zIndex}
        filter='drop-shadow(0px 0px 14px rgba(0, 0, 0, 1))'
      />

      <Flex
        position='fixed'
        bottom={buttonBottom + 'px'}
        right={buttonRight + 12 + 'px'}
        gap='2'
        alignItems='center'
        justifyContent='end'
        onClick={() => handleClick(ROUTES.QUESTS)}
        cursor='pointer'
        visibility={isOpen ? 'visible' : 'hidden'}
        opacity={isOpen ? 1 : 0}
        transform={isOpen ? 'translateY(-100px)' : 'translateY(0%)'}
        transition='all 0.1s ease-out'
        zIndex={zIndex}
      >
        <Text color='white'>{t('navbar.gamesMenu.quests')}</Text>
        <Image src={Quests} w='45px' alt='Quests' />
      </Flex>

      <Flex
        position='fixed'
        bottom={buttonBottom + buttonsSeparation + 'px'}
        right={buttonRight + 12 + 'px'}
        gap='2'
        alignItems='center'
        justifyContent='end'
        onClick={() => handleClick(ROUTES.POLLS)}
        cursor='pointer'
        visibility={isOpen ? 'visible' : 'hidden'}
        opacity={isOpen ? 1 : 0}
        transform={isOpen ? 'translateY(-100px)' : 'translateY(0%)'}
        transition='all 0.1s ease-out'
        transitionDelay='0.1s'
        zIndex={zIndex}
      >
        <Text color='white'>{t('navbar.communityMenu.polls')}</Text>
        <Image src={Polls} w='45px' alt='Polls' />
      </Flex>

      <Flex
        position='fixed'
        bottom={buttonBottom + buttonsSeparation * 2 + 'px'}
        right={buttonRight + 12 + 'px'}
        gap='2'
        alignItems='center'
        justifyContent='end'
        onClick={() => handleClick(ROUTES.TREASURES_GAME)}
        cursor='pointer'
        visibility={isOpen ? 'visible' : 'hidden'}
        opacity={isOpen ? 1 : 0}
        transform={isOpen ? 'translateY(-100px)' : 'translateY(0%)'}
        transition='all 0.2s ease-out'
        transitionDelay='0.1s'
        zIndex={zIndex}
      > 
        <Text color='white'>{t('navbar.gamesMenu.miniGames')}</Text>
        <Image src={Minigames} w='45px' alt='Minigames' />
      </Flex>

    </>
  )
}

export default FlyingButtons
