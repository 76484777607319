import { Box, Flex, HStack, useMediaQuery } from "@chakra-ui/react"
import { useAuthStore } from "@olagg/store"
import { Alert } from "../Alert"
import MyAccountNav from "./MyAccountNav"
import Notifications from "../Notifications"
import LeftMenu from "./LeftMenu"
import LanguageMenu from "./LanguageMenu"
import StreaksAndNotifications from "./StreaksAndNotifications"
import { NotificationsProvider } from "../Notifications/hooks/useNotifications"
import CtaButtons from "./CtaButtons"
import LoginButton from "./LoginButton"
import CompletionSnack from "../CompletionSnack"
import { useState, useEffect } from "react"
import { useLocation } from "react-router-dom"
import { ROUTES } from "@/routes"

const Navbar = {
  bgColor: '#2B2640',
  bgGradient: 'linear-gradient(to bottom, #1B094D, #300863)',
}

const NavbarV3 = () => {
  const { loggedIn, me } = useAuthStore()
  const [isLargerThan1024] = useMediaQuery('(min-width: 1024px)')
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const { pathname } = useLocation()
  const isProfilePath = pathname.includes(ROUTES.MY_PROFILE)

  const consentManager = document.getElementById("consentManager")
  const [isConsentOpened, setIsConsentOpened] = useState(false)
  
  useEffect(()=> {
    if (consentManager?.clientHeight > 0) setIsConsentOpened(true)
  }, [consentManager?.clientHeight])

  return (
    <>
      <Flex
        position="sticky"
        top='0'
        w="100vw"
        h={'82px'}
        maxW='100%'
        zIndex={999}
        alignItems='center'
        bgColor={Navbar.bgColor}
        bg={Navbar.bgGradient}
        borderBottom="2px solid var(--OLA-GG-colors-Gradient, #4E10FF)"
        sx={{
          '@supports (border-image: linear-gradient(to right, #4E10FF, #AB17DF))': {
            borderImage: 'linear-gradient(to right, #4E10FF, #AB17DF) 1',
          }
        }}
      >
        <Box width='100%' p='0' transition='all ease 0.3s'>
          <HStack pb='0' justifyContent='space-between' paddingX="20px" margin='0 auto'>
            <NotificationsProvider>
              <LeftMenu />
              <Flex alignItems='center' gap='2' justifyContent='flex-end' w='full'>
                {isLargerThan1024 && (
                  <>
                    <LanguageMenu />
                    <CtaButtons />
                  </>
                )}

                {!loggedIn
                  ? <LoginButton />
                  : (
                    <>
                      <MyAccountNav />
                      <Notifications />
                      {!isMobile && (
                        <StreaksAndNotifications
                          streaks={0}
                          notifications={me?.unreadNotifications}
                        />
                      )}
                      {!isProfilePath && isLargerThan1024 && (
                        <Flex position={'fixed'} bottom={isConsentOpened ? '90px' : '20px'} left={'20px'}>
                          <CompletionSnack />
                        </Flex>
                      )}
                    </>
                  )
                }
              </Flex>
            </NotificationsProvider>
          </HStack>
        </Box>
      </Flex>
      <Alert />
    </>
  )
}

export default NavbarV3
