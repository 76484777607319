import { fetcher, NotificationsEndpoints } from '@olagg/api-hooks';
import { Notification } from '@olagg/db-types';
import { useAuthStore } from '@olagg/store';
import { analytics } from '@olagg/ui-kit/Analytics';
import { useState, createContext, useContext } from 'react';

export interface IUseNotifications {
  page: number,
  limit: number
}

const notificationsContext = createContext({
  all: [],
  getAll: (filter?: IUseNotifications) => {},
  read: [],
  getRead: (filter?: IUseNotifications) => {},
  unread: [],
  getUnread: (filter?: IUseNotifications) => {},
  isLoading: false,
  markAsRead: (notification: Notification) => {},
  markAsUnread: (notification: Notification) => {},
  markAllAsRead: () => {},
  isOpen: false,
  onOpen: () => {},
  onClose: () => {},
});

export default function useNotifications() {
  return useContext(notificationsContext);
}

export const NotificationsProvider = ({ children }: { children: React.ReactNode }) => {
  const { me, getMe } = useAuthStore();

  const [read, setRead] = useState<Notification[]>([]);
  const [unread, setUnread] = useState<Notification[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const defaultFilter: IUseNotifications = {
    page: 1,
    limit: 50
  }

  const triggerAnalytics = () => {
    analytics()?.track("Notifications Viewed", {
      notifications_count: me?.unreadNotifications
    })
  }

  const onOpen = () => {
    getAll();
    triggerAnalytics();
    setIsOpen(true);
  }

  const onClose = () => {
    setIsOpen(false);
  }

  const getAll = (filter?: IUseNotifications) => { getMe(); getRead(filter); getUnread(filter) };
  const getRead = (filter?: IUseNotifications) => get(filter, setRead, 'read');
  const getUnread = (filter?: IUseNotifications) => get(filter, setUnread, 'unread');
  
  const get = (_filter = defaultFilter, setFn: any, status?: 'read' | 'unread') => {
    setIsLoading(true);
    fetcher(NotificationsEndpoints.get({ ..._filter, status }))
      .then(res => {
        setFn(res.notifications);
      })
      .catch(error => {
        setFn([]);
        console.log(error)        
      })
      .finally(() => {
        setIsLoading(false);
      })
  } 

  const markAsRead = (notification: Notification) => {
    fetcher(NotificationsEndpoints.markAsRead(notification.id))
      .then(() => {
        getAll();
      })
      .catch(error => {
        console.log(error)        
      })
  } 

  const markAsUnread = (notification: Notification) => {
    fetcher(NotificationsEndpoints.markAsUnread(notification.id))
      .then(() => {
        getAll();
      })
    .catch(error => {
        console.log(error)        
      })
  } 

  const markAllAsRead = () => {
    fetcher(NotificationsEndpoints.markAllAsRead())
      .then(() => {
        getAll();
      })
      .catch(error => {
        console.log(error)        
      })
  } 

  return (
    <notificationsContext.Provider
      value={{
        all: {
          ...read,
          ...unread
        },
        getAll,
        read,
        getRead,
        unread,
        getUnread,
        isLoading,
        markAsRead,
        markAsUnread,
        markAllAsRead,
        isOpen,
        onOpen,
        onClose,
      }
    }>
      {children}
    </notificationsContext.Provider>
  )
}
