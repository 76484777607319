import { Flex, Image, Text, useMediaQuery } from "@chakra-ui/react";
import streakIcon from './assets/fire.png'
import notificationIcon from './assets/bell.png'
import useNotifications from "../Notifications/hooks/useNotifications";

const StreaksAndNotifications = ({
  streaks,
  notifications,
  onOpenStreaks,
  onOpenNotifications
}: {
  streaks: number,
  notifications: number,
  onOpenStreaks?: () => void,
  onOpenNotifications?: () => void
}) => {
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const { onOpen: openNotifications } = useNotifications();

  const handleOpenStreaks = () => {
    onOpenStreaks?.()
    // openStreaks() // TODO
  }

  const handleOpenNotifications = () => {
    onOpenNotifications?.()
    openNotifications()
  }

  return (
    <Flex
      gap='2'
      color='white'
      fontWeight='600'
      fontSize='14px'
      alignItems='center'
      justifyContent='center'
      mt={isMobile ? '6' : '0'}
    >
      {/*
      // TODO: sacar comment cuando este listo rachas
      <Flex
        cursor='pointer' onClick={handleOpenStreaks}
        alignItems='center' justifyContent='flex-start' gap='2' p={isMobile ? '2' : '10px'}
        background='#370A70' borderRadius='10' w={isMobile ? 'full' : 'auto'}
        _hover={{ bg: 'whiteAlpha.500' }}
      >
        <Image src={streakIcon} w='24px' h='28px' alt='streak' />
        <Text pr='1'>{streaks}</Text>
      </Flex> */}
      <Flex
        cursor='pointer' onClick={handleOpenNotifications}
        alignItems='center' justifyContent='flex-start' gap='2' p={isMobile ? '2' : '10px'}
        background='#370A70' borderRadius='10' w={isMobile ? 'full' : 'auto'}
        _hover={{ bg: 'whiteAlpha.500' }}
      >
        <Image src={notificationIcon} w='24px' h='28px' alt='notification' />
        {notifications > 0 && <Text pr='1'>{`${notifications > 99 ? '99+' : notifications}`}</Text>}
      </Flex>
    </Flex>
  )
};

export default StreaksAndNotifications;
