import { AUTH_METHODS, STEPS } from "../constants";
import { Button, Flex, InputGroup, InputRightElement, Link, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text, useBreakpointValue, useToast, Show, Hide } from "@chakra-ui/react";

import OlaGGFormInput from "../../../components/Form/OlaggFormInput";
import OlaggForm from "../../../components/Form/OlaggForm";
import { ROUTES } from "../../../routes";
import { analytics } from "../../../analytics";
import useAuth from "../hooks/useAuth";
import { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useOnboardingStore } from "@olagg/store";
import { AdTrackingContext } from '@/contexts/adTrackingContext';
import { useTranslation } from "react-i18next";
import { NavbarLogoOla } from '@olagg/ui-kit'

type IProps = {
  email: string,
  changeEmail: (email: string) => void,
  isInvalid: boolean
}

function IconFor({ method }: { method: string }) {
  const IconComponent = AUTH_METHODS[method].icon;

  return <IconComponent />
}

const ChooseMethod = ({ changeEmail, isInvalid }: IProps) => {
  const { fcb, fbp } = useContext(AdTrackingContext)
  const { t } = useTranslation();
  const { isOnboardingOpen, step, setStep, closeOnboarding, forceDiscord } = useOnboardingStore()
  const { loginWithProvider, handleUserToken, autoFill, abortController } = useAuth()
  const toast = useToast()
  const useFormMethods = useForm({
    mode: 'onChange',
    shouldUseNativeValidation: false,
  })

  const navigate = useNavigate();

  const handleViewPolicy = (path: string) => () => {
    closeOnboarding();
    navigate(`${ROUTES.POSTS}${path}`);
  }

  const handleLogInWithProvider = (provider: string) => () => loginWithProvider(provider)

  const handleSubmit = async (formData) => {
    changeEmail(formData.email);
    setStep(STEPS.verificationCode);
  }

  const clearEmail = () => {
    useFormMethods.setValue('email', '')
    changeEmail('')
  }

  const enablePasskeyAutofill = () =>
    autoFill()
      .then((resp) => {
        changeEmail(resp.user.email)
        if (!resp.user.verified) {
          setStep(STEPS.verificationCode)
        } else if (forceDiscord) {
          setStep(STEPS.linkDiscord)
        } else {
          handleUserToken(resp, 'passkey')
          closeOnboarding()
        }
      })
      .catch((err) => {
        if (err.error === 'no_matching_credential_found') {
          toast({
            title: t('Login.enablePasskeyAutofill.invalidPassKeyTitle'),
            description: t('Login.enablePasskeyAutofill.invalidPassKeyDescription'),
            status: 'error',
            duration: 9000,
            isClosable: true,

          })
          let props = {
            reason: "invalid passkey",
            user_agent: navigator.userAgent
          }
          if (fcb != null) props.fbc = fcb
          if (fbp != null) props.fbp = fbp
          analytics()?.track("User SignIn Failed", props)
          enablePasskeyAutofill()
        }
      })

  useEffect(() => {
    if (isOnboardingOpen) enablePasskeyAutofill()
    return () => abortController.abort();
  }, [isOnboardingOpen])

  const isMobile = useBreakpointValue(
    {
      base: true,
      md: false,
    },
    {
      fallback: 'md',
    },
  )

  const { pathname } = useLocation()
  const isLoginPath = pathname.includes(ROUTES.LOGIN)

  return (
    <Modal
      isOpen={isOnboardingOpen && step === STEPS.chooseMethod}
      onClose={closeOnboarding}
      isCentered
      size={'full'}
      scrollBehavior='outside'
      closeOnEsc={!isLoginPath}
      closeOnOverlayClick={!isLoginPath}
    >
      <ModalOverlay backdropFilter='blur(10px)' />
      <ModalContent bg='transparent'
        height={{ base: '100%', md: '100vh' }}
        width={{ base: '100%', md: '100vw' }}
        overflow={{ base: 'auto', md: 'hidden' }}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <ModalBody
          padding={0}
          sx={{
            backgroundColor: '#0C0324',
            background: 'url(/images/bg_modal.png)',
            backgroundSize: 'cover'
          }}
          m='0'
          display={'flex'}
          flexDirection={'row'}
          w='100%'
          h='100%'
          maxW={{ base: '100%', '2xl': '1280' }}
          maxH={{ base: '100%', '2xl': '720px' }}

        >
          <Flex flex='1'>
            <Flex flex={{ base: '1', md: '1.1' }}
              p={{ base: '16px', md: '32px' }}
              flexDir={'column'}
              gap='24px'
            >
              <Show below="md">
                {!isLoginPath && <ModalCloseButton bg='rgba(0,0,0,0.4)' borderRadius="50%" top='10px' right="10px" zIndex='2' color={'white'} />}
              </Show>
              <Flex>
                <NavbarLogoOla />
              </Flex>

              <Text as='span' fontSize={{ base: '20px', md: '24px' }} fontWeight='700' color={'white'}>
                <Text variant={'sunGradient'} as='span'>{t('OnBoarding.ChooseMethod.welcome.hi')}</Text> {t('OnBoarding.ChooseMethod.welcome.title1')}<br /> {t('OnBoarding.ChooseMethod.welcome.title2')}
              </Text>

              <Show above="md">
                <Flex overflowY={'auto'} minHeight='0px' maxHeight={'120px'}>
                  <Text as='span' fontSize={'14px'} fontWeight='400' color={'white'}>
                    <Text as='strong'>{t('OnBoarding.ChooseMethod.welcome.lead1')}</Text> {t('OnBoarding.ChooseMethod.welcome.lead2')}
                    <br /><br />
                    {t('OnBoarding.ChooseMethod.welcome.lead3')}
                  </Text>
                </Flex>
              </Show>

              <Flex flexDirection={'column'} gap='12px' mt='8px'>

                {Object.keys(AUTH_METHODS).map((method, index) => (
                  AUTH_METHODS[method].active && (
                    <Button
                      fontSize={{ base: "xs", md: "lg" }}
                      leftIcon={<IconFor method={method} />}
                      w={'100%'}
                      key={index}
                      variant="outline"
                      onClick={handleLogInWithProvider(method)}
                      _hover={{
                        background: 'linear-gradient(90deg, #4E10FF 0%, #AB17DF 100%)'
                      }}
                    >
                      {t(`OnBoarding.ChooseMethod.accessWith.${AUTH_METHODS[method].text}`)}
                    </Button>
                  )
                ))}

                <Flex flexDirection={'row'} alignItems='center'>
                  <Flex flex={1} h='1px' bgColor={'white'} />
                  <Text color='white' fontSize={'14px'} p='8px'>ó</Text>
                  <Flex flex={1} h='1px' bgColor={'white'} />
                </Flex>

                <Flex flexDirection={'column'}>
                  <Text color='white'>{t('OnBoarding.ChooseMethod.accessWith.emailLabel')}</Text>
                  <OlaggForm
                    useFormMethods={useFormMethods}
                    onSubmit={handleSubmit}
                  >
                    <InputGroup size='md'>
                      <OlaGGFormInput
                        type="email"
                        required={true}
                        controlName="email"
                        placeholder={t('OnBoarding.ChooseMethod.accessWith.placeholder')}
                        autoComplete="username webauthn"
                        customStyles={{ backgroundColor: 'white', height: '48px' }}

                      />
                      <InputRightElement >
                        <Button size='lg' onClick={clearEmail} variant="unstyled" mt="6" color="gray.400">
                          x
                        </Button>
                      </InputRightElement>
                    </InputGroup>
                    {isInvalid && <Text color='red.500' fontSize='sm' mt='1'>{t('OnBoarding.ChooseMethod.accessWith.invalidEmail')}</Text>}
                    <Button
                      type='submit'
                      width={'100%'}
                      variant={'filledGradient'}
                      fontSize={{ base: "sm", md: "md" }}
                      disabled={!useFormMethods.formState.isValid}
                    >
                      {t('OnBoarding.ChooseMethod.accessWith.email')}
                    </Button>
                  </OlaggForm>

                </Flex>
              </Flex>
              <Flex justifyContent='center' textAlign={{ base: 'left', md: 'center' }} px={{ base: '16px', md: '25px' }} py={2} color='white' fontSize={{ base: "xs", md: "sm" }} mt='auto'>
                <Text fontSize={'12px'}>
                  {t('OnBoarding.ChooseMethod.tyc.text1')}
                  <Link onClick={handleViewPolicy('/tyc')} mx='1' textDecoration={'underline'}>{t('OnBoarding.ChooseMethod.tyc.tyc')} </Link> {t('OnBoarding.ChooseMethod.tyc.text2')}
                  <Link onClick={handleViewPolicy('/privacidad')} textDecoration={'underline'} mx='1'>{t('OnBoarding.ChooseMethod.tyc.pp')}</Link>
                </Text>
              </Flex>
            </Flex>
            <Show above="md">
              <Flex flex='1' bgColor='rgba(12,3,36,1)' position={'relative'}>
                {!isLoginPath && <ModalCloseButton bg='rgba(0,0,0,0.4)' borderRadius="50%" top='10px' right="10px" zIndex='2' color={'white'} />}
                <video
                  style={{
                    height: '100%',
                    width: '100%',
                    objectFit: 'cover'
                  }}
                  loop
                  muted
                  autoPlay
                  preload="auto"
                  playsInline
                >
                  <source src={'https://cdn.newsroom.gg/medium/000/008/919/Videoshop_2024-04-24_13-11-14-913.mp4'} type="video/mp4" />
                </video>
              </Flex>
            </Show>
          </Flex>

        </ModalBody>
      </ModalContent>
    </Modal>
  )
}

export default ChooseMethod;
