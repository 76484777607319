export const Market = ({
  height = '20px',
  width = '20px',
  color = 'currentColor',
}: React.SVGProps<SVGSVGElement>) => {
  return (
    <svg width={width} height={height} viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M17.148 1.5368C16.9698 1.24132 16.7185 0.996746 16.4183 0.826667C16.118 0.656588 15.779 0.566743 15.434 0.565796H4.566C3.868 0.565796 3.211 0.937796 2.852 1.5368L0.143 6.0508C0.0492558 6.20621 -0.000194965 6.3843 5.77679e-07 6.5658C0.00399429 7.53101 0.359495 8.46171 1 9.1838V17.5658C1 18.6688 1.897 19.5658 3 19.5658H17C18.103 19.5658 19 18.6688 19 17.5658V9.1838C19.6405 8.46171 19.996 7.53101 20 6.5658C20.0002 6.3843 19.9507 6.20621 19.857 6.0508L17.148 1.5368ZM17.984 6.8168C17.9223 7.29962 17.6868 7.74344 17.3217 8.06532C16.9566 8.3872 16.4867 8.56511 16 8.5658C14.897 8.5658 14 7.6688 14 6.5658C14 6.4978 13.975 6.4378 13.961 6.3738L13.981 6.3698L13.22 2.5658H15.434L17.984 6.8168ZM8.819 2.5658H11.18L11.993 6.6308C11.958 7.7028 11.08 8.5658 10 8.5658C8.92 8.5658 8.042 7.7028 8.007 6.6308L8.819 2.5658ZM4.566 2.5658H6.78L6.02 6.3698L6.04 6.3738C6.025 6.4378 6 6.4978 6 6.5658C6 7.6688 5.103 8.5658 4 8.5658C3.51325 8.56511 3.04341 8.3872 2.67828 8.06532C2.31315 7.74344 2.07772 7.29962 2.016 6.8168L4.566 2.5658ZM8 17.5658V14.5658H12V17.5658H8ZM14 17.5658V14.5658C14 13.4628 13.103 12.5658 12 12.5658H8C6.897 12.5658 6 13.4628 6 14.5658V17.5658H3V10.4238C3.321 10.5068 3.652 10.5658 4 10.5658C4.56782 10.5661 5.12915 10.4452 5.6465 10.2112C6.16386 9.97717 6.6253 9.63543 7 9.2088C7.733 10.0408 8.807 10.5658 10 10.5658C11.193 10.5658 12.267 10.0408 13 9.2088C13.3747 9.63543 13.8361 9.97717 14.3535 10.2112C14.8708 10.4452 15.4322 10.5661 16 10.5658C16.348 10.5658 16.679 10.5068 17 10.4238V17.5658H14Z" fill={color}/>
    </svg>
  )
}

export default Market

