import { Flex, Box, CloseButton, Text } from '@chakra-ui/react'
import { useAuthStore } from "@olagg/store";
import { useTranslation } from 'react-i18next';
import NotificationsTabs from "./Tabs";
import useNotifications from "./hooks/useNotifications";

export default function Notifications() {
  const { t } = useTranslation();
  const { me } = useAuthStore();
  const { read, unread, getAll, isLoading, markAsRead, markAsUnread, isOpen, onClose } = useNotifications(); 

  if (!me) return null;
 
  return (
    <Box
      position='absolute'
      top='82px'
      right={{ base: '0', sm: '8' }}
      w={{ base: 'full', sm: '400px' }}
      overflow='hidden'
      bgColor='#3F2091' border='none' color='white'
      borderRadius={{ base: '0', sm: '10' }}
      opacity={isOpen ? 1 : 0}
      visibility={isOpen ? 'visible' : 'hidden'}
      transition='all 0.3s ease-in-out'
    >
      <Flex justifyContent='space-between' alignItems='center' px='3' my='4'>
        <Text ml='1' fontWeight='semibold'>{t('Notifications.title')}</Text>
        <CloseButton p='0' color='white' onClick={onClose} />
      </Flex>
      <NotificationsTabs
        read={read}
        unread={unread}
        isLoading={isLoading}
        markAsRead={markAsRead}
        markAsUnread={markAsUnread}
      />
    </Box>
  )
}
