import { Box, Flex, Show, useMediaQuery } from '@chakra-ui/react'
import { Outlet, useLocation } from 'react-router-dom'
import { ROUTES, secondaryRoutes } from '../routes'
import Authenticate from '../modules/Auth/Authenticate'
import CookiesConsent from './CookiesConsent'
import { FC, Suspense } from 'react'
import Footer from './Footer'
import NavbarV3 from './NavbarV3'
import { useAuthStore } from '@olagg/store'
import { useEffect } from 'react'
import { Helmet } from 'react-helmet';
import Loading from '../pages/Loading';
import CompletionSnack from './CompletionSnack'
import FlyingButtons from './NavbarV3/FlyingButtons'

interface IProps {
  useImage?: boolean
}

const Layout: FC<IProps> = () => {
  const [isLargerThan768] = useMediaQuery('(min-width: 768px)')
  const [isMobile] = useMediaQuery('(max-width: 480px)')
  const { pathname } = useLocation()
  const { loggedIn } = useAuthStore()
  const isLanding = pathname.includes(ROUTES.LANDING)
  const isLandingSecure = pathname.includes(ROUTES.LANDING_SECURE)
  const isPublicProfile = pathname.includes(ROUTES.PUBLIC_PROFILE)
  const isSecondary = secondaryRoutes.includes(pathname)
  const isProfilePath = pathname.includes(ROUTES.MY_PROFILE)
  const isMiami = pathname.includes(ROUTES.MIAMI)
  const maxWidth = isMiami ? '100%' : import.meta.env.VITE_LAYOUT_MAXWIDTH;

  const background = (() => {
    return '#0C0324'
    switch (true) {
      case isPublicProfile:
        return isLargerThan768 ? 'linear-gradient(45deg, #912FD4 54%, #C1698A 110%)' : 'linear-gradient(45deg, #C1698A 87%, #912FD4 97%)'
      default:
        return '#0C0324' //'linear-gradient(0deg, #0c0324 0%, #240340 100%)'
    }
  })()

  useEffect(() => {
    window.scrollTo(0, 0);
    document.body.style.overflow = 'auto'
    document.body.style.marginLeft = '0'
  }, [pathname]);

  return (
    <>
      <Helmet>
        <link rel="canonical" href={`https://olagg.io${pathname}`} />
      </Helmet>
      <Authenticate />
      <Box
        background={background}
        minHeight="100vh"
        position="relative"
        w='100vw'
        maxW='100%'
        overflowX='clip'
        top='0'
      >
        <CookiesConsent />
        <NavbarV3 />
        <Box
          maxWidth={maxWidth}
          margin="0 auto"
          id='layout'
          paddingX={isMiami ? 0 : { base: '12px', lg: '30px' }}
        >
          {!isProfilePath && (
            loggedIn && (
              <Show below='md'>
                <Flex px='8px' mt='16px'>
                  <CompletionSnack />
                </Flex>
              </Show>
            )
          )}
          <Suspense fallback={<Loading />} >
            <Outlet />
          </Suspense>
        </Box>
        {!isLanding && !isSecondary && (
          <Footer />
        )}

        {isLandingSecure && !loggedIn && (
          <Footer />
        )}

        {isMobile && (
          <FlyingButtons />
        )}
      </Box>
    </>
  )
}

export default Layout
